@import '../../../assets/sass/variables';
$_heading-color: accent-palette(500);
$_hover_color: accent-palette(300);

.custom_blue {
  background: $main-color !important;
}

.doctors_float_button_toggle {
  & .ant-float-btn-body {
    background: $_heading-color !important;

    &:hover {
      background: $_hover_color !important;
    }
  }
  & svg {
    color: white;
  }
  & i {
    color: white;
  }
}
.doctors_float_button {
  height: 3.5em;
  width: 3.5em;

  & .ant-float-btn-body {
    background: $_heading-color !important;

    &:hover {
      background: $_hover_color !important;
    }
  }

  & svg {
    height: 1.5em;
    width: 1.2em;
    color: white;
  }
  & i {
    color: white;
  }
}
.doctors_archive_float_button {
  height: 3.5em;
  width: 3.5em;

  & .ant-float-btn-body {
    background: darken($info-color, 10%) !important;

    &:hover {
      background: $info-color !important;
    }
  }

  & svg {
    height: 1.5em;
    width: 1.2em;
    color: white;
  }
  & i {
    color: white;
  }
}

.custom_dropdown {
  background: #ebebeb;
  border-radius: 20px;
  padding: 0.7em 2em;
  display: block;
  height: 40px;
  border: #ebebeb 1px solid;
  color: #0d3aaa;
  line-height: 1.4285714286;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    color 0.2s ease-in-out;
  will-change: background, border, box-shadow, color;
}
.custom_dropdown span {
  margin-left: 1em;
}
