.patient_report_form_parent {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
.form_label_div {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  & label {
    max-width: 20%;
    width: 100%;
    text-wrap: wrap;
    @media screen and (min-width: 1300px) {
      max-width: 10%;
    }
    @media screen and (min-width: 768px) {
      max-width: 15%;
    }
  }
  & .input_number {
    width: 80%;
    background-color: '#ebebeb' !important;
    border-radius: 20px;
    padding: 5px 0;
  }
  & .select_input {
    width: 80% !important;
  }
}
.patient-report-form {
  width: 100%;
  margin: 0 auto;
}
