@import '../../../assets/sass/variables';

$_heading-color: accent-palette(500);
$_hover_color: accent-palette(300);

.header {
  display: flex;
  justify-content: space-between;

  & .report_heading {
    color: $_heading-color;
    text-transform: capitalize;
    font-size: 1.25rem !important;

    @media (min-width: 950px) {
      font-size: 1.75rem !important;
    }
  }

  & .report_logo {
    height: 5rem;
    width: 5rem;
    object-fit: contain;
  }
}

.user-info {
  font-size: 1rem;
  font-weight: bold;
}

.custom-gap {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.table_gap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
// .table_row_gap {
//   gap: 5rem;
// }
.section {
  &:not(:last-child) {
    border-bottom: 1px solid lightgray;
  }
  padding: 10px 0;
}

.temp-container {
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0;
  flex-wrap: wrap;
  gap: 2rem;
  @media (min-width: 950px) {
    gap: 1rem;
  }
}

.info-container {
  justify-content: space-between;
}

.column {
  flex: none;

  @media (min-width: 950px) {
    flex: 1 1 30%;
    max-width: 100%;
  }
}

.table_heading:first-child {
  width: 55%;
}

.table_heading {
  margin: 0;
  max-height: 4rem;
  font-weight: bold;
  font-size: 1.15rem;
  width: 31%;
}

.table_row_gap {
  display: flex;

  width: 100%;
  justify-content: space-between;
}
.table_row_gap td:first-child {
  width: 30%;
}
.investigation_setting {
  align-items: center;

  display: flex;
}
.result_setting {
  align-items: center;
  width: 30%;
  display: flex;
  justify-content: center;
}
.table_row_gap td {
  margin: 0;
  align-items: center;

  background: #faf7f7;
  border-bottom: 1px solid white;
}

.rangeValue:first-child {
  padding-left: 0;
  border: none;
}
.rangeValue {
  padding: 0 0.8em 0;
  gap: 0.5rem;
  border-left: 1px solid white;
}
.rangeValue:last-child {
  padding-right: 0;
}

.section_title {
  margin-top: 1em;
}

.img-grid {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: center;
  align-items: center;
}

.table_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
}

.float_button {
  height: 3.5em;
  width: 3.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $_heading-color !important;
  & .ant-float-btn-body,
  .ant-float-btn-conten {
    background: $_heading-color !important;
    &:hover {
      background: $_hover_color !important;
    }
  }

  & svg {
    height: 1.5em;
    width: 1.2em;
    color: white;
  }
  & i {
    color: white;
  }
  &:hover {
    background: $_hover_color;
  }
}

.findings_sections h6 {
  line-height: 1em;
  margin: 8px 0;
  font-weight: bolder;
  font-size: 15px;
}

.findings_sections p {
  line-height: 1em;
  margin: 0 0 15px 0;
}

.head_image_logo {
  justify-content: space-between;
  gap: 1rem;
}
.ranges-table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100% !important;
}
.ranges_setting {
  width: 50% !important;
}
.condition_setting {
  width: 50% !important;
}
// .values_setting {
//   width: 20% !important;
// }
.ref_value_width,
.heading_custom_width {
  width: 40% !important;
}
.section_title_bot {
  width: 30% !important;
  display: flex;
  gap: 1rem;
}
.range_vange {
  width: 36px !important;
}
