@import '../../assets/sass/variables';
$_toggle-color: main-palette(500);
$_link-color: main-palette(500);

.active_toggle_color {
  background-color: $_toggle-color;
  border-color: $_toggle-color;
}
.Inactive_toggle_color {
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.link_font {
  font-weight: 600;
}
.form_div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.sign_in_forget_div {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.sigIn_modal_css {
  display: flex;
  flex-direction: column;
  & p {
    font-size: smaller;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid lightgray;
  }
}

.terms_div {
  margin-inline: auto;
  width: 50%;
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
}
