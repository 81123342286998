@import '../../../assets/sass/variables';
.text {
  display: flex;
  flex-direction: column;
}
.form-header {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-input {
  width: 100% !important;
}
.checkbox_div {
  width: 22%;
  display: flex;
  align-items: center;
}
.formula_input_div {
  grid-column: span 2;
  & Label {
    margin-bottom: 0 !important;
  }
}
.card_section {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem 2rem;
  margin-top: 1rem;
  border-radius: 8px;
  position: relative;
}

.card_section_title {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;

  & Button {
    margin-left: auto;
  }
}
.card_create_section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem;
  border-radius: 8px;
}
.card_create_section_header {
  gap: 1rem;
}
.form_input_label {
  display: flex;
  justify-content: space-between;
}
.card_section_hint {
  color: red;
  font-size: small;
  text-align: end;
  margin-bottom: 0;
}
.nodes {
  color: red;
}

.input_setup {
  margin-left: 5rem;
  margin-top: -2rem;
}
.nodes_style {
  color: red;

  margin-top: 2rem;
  margin-left: 3rem;
}
.card_hint {
  color: red;
  font-size: 0.78em;
  margin-bottom: 0;
}
.card_create_sub_section {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
}
.form_label_div {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  & Label {
    font-weight: bold;
  }
}
.form_label_width {
  width: 30%;
}
.sub_section_inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.sub_field_inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
}
.sub_section_title {
  margin: 0;
}
.sub_section_btns {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}
.sub_section_inputs_parent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.parameter_btns {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.range_btns {
  display: flex;
  gap: 0.5rem;
  align-self: flex-end;
}
.range_title_div {
  display: flex;
  justify-content: space-between;
}

.expandable-button {
  transition: all 0.3s ease;
}

.range-container {
  background: #f5f5f59c;
  border-radius: 12px;
  padding: 15px;
}
.submit_button {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.findings_div {
  gap: 1rem;
  padding-top: 1.5rem;
  margin-top: 0;
}
.findings_section {
  margin-top: 5rem;
}
.findings-container {
  gap: 1rem;
  display: flex;
  flex-direction: column;
}
.findings_double_value_div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  .findings_double_value_description {
    grid-column: span 2;
  }
}

.table_field {
  border: 1px solid lightgray;
}

.table_field thead {
  background-color: #d3d3d370;
}

.deleteButton {
  border-radius: 50% !important;
  background: #635f5f !important;
  color: #635f5f !important;
  padding: 0.6em !important;
  font-size: 1.2em !important;
  height: 1em !important;
  width: 1em !important;
  position: absolute !important;
  z-index: 9999 !important;
  top: 9px;
  right: 8px;
}

.deleteButton:hover {
  background-color: red !important;
  color: rgb(255, 0, 0) !important;
}

.settingButton {
  color: black !important;
  font-size: 1.3em !important;
}

.settingButton:hover {
  color: #7098fc !important;
}

.menuItem {
  background: $error-color !important;
  color: $error-contrast !important;
  &:hover {
    color: darken($error-contrast, 10%) !important;
    background: darken($error-color, 10%) !important ;
  }
}

.multiple_finding {
  position: relative;
}

.close_button {
  background: red;
  border: none;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  color: #ffff;
  height: 22px;
  width: 22px;
}

.ant-select-selector {
  overflow-y: auto;
  min-height: 40px;
  overflow: hidden;
  height: auto !important;
}
.report_secet {
  .ant-select-selector {
    background-color: white !important;
    color: #635f5f;
  }
}

.findingCategory .create_form_label_div {
  & label {
    width: 12rem;
  }
}

.sectionStyle {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 8px;
  margin-top: 32px;
  position: relative;
}

.floatHeading {
  position: absolute;
  top: -15px;
  left: 13px;
  padding: 0 8px;
  font-size: 18px;
  font-weight: normal;
  background: white;
  color: darkgray;
}

.delete_section {
  &:hover {
    background: red !important;
  }
}
.option_style {
  line-height: 2rem !important;
  word-break: break-all;
}

.option_style {
  span {
    white-space: nowrap !important;
  }
}
