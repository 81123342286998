@import '../variables';

$_bg: rgba(#ebebeb, 0.3);
$_border-color: #ebebeb;
$_border-width: 2px;
$_td-border-color: #fff;
$_td-border-width: 2px;
$_header-bg: rgba(main-palette(500), 0.1);
$_header-color: main-palette(500);
$_striped-bg: rgba(main-palette(500), 0.08);

$_shape: $shape;
$_animation: $animation;
$_shadow: 0 0 10px rgba(#000, 0.12);

.ant-table-expanded-row .ant-table {
  margin: 0 !important;
}

.ant-table-wrapper {
  color: white !important;
  .ant-pagination:last-child {
    margin-bottom: 0;
  }

  .ant-table-container {
    border-radius: 0;
  }

  table {
    border-radius: 0;
  }

  .ant-table {
    overflow: auto;
    border-radius: 0;

    tr {
      transition: box-shadow 0.2s ease-in-out;

      th {
        white-space: nowrap;
      }

      th,
      td {
        border: 0;
        border-bottom: $_td-border-width solid $_td-border-color;
        border-radius: 0 !important;
        padding: $_module-rem *0.85;
        line-height: 20px;

        &:first-child {
          padding-left: $_module-rem * 2;
        }
        &:last-child {
          padding-right: $_module-rem * 2;
        }
      }
    }

    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      transform: scale(1);
      z-index: 1;

      > td {
        border-color: transparent;
        background: $_bg;
      }
    }

    .ant-table-expanded-row {
      overflow: hidden;

      > td {
        padding: 0 !important;
      }

      table tr th {
        border-radius: 0;
      }

      td + td {
        overflow: hidden;
        padding-top: $module-rem * 1.6;
        padding-bottom: $module-rem * 1.6;
      }
    }
  }

  .ant-table-thead {
    tr {
      th,
      td {
        background-color: $_header-bg;
        color: $_header-color;
        font-weight: 700;
        position: relative;
        height: 40px;
      }

      th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
        background: accent-palette(300);
      }

      .ant-table-column-sorter-full {
        margin-top: 0 !important;
      }

      .ant-table-column-sorters {
        align-items: center;
        display: flex !important;
        padding: 0;
      }

      .ant-table-column-sorter-inner,
      .ant-table-column-sorter-inner-full {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 $module-rem !important;
        justify-content: space-between;

        .ant-table-column-sorter-up,
        .ant-table-column-sorter-down {
          font-family: 'IcoFont' !important;
          speak: none;
          font-style: normal;
          font-weight: 700;
          font-size: 0.9rem;
          font-variant: normal;
          text-transform: none;
          white-space: nowrap;
          word-wrap: normal;
          direction: ltr;
          line-height: 1;

          &:before {
            display: inline;
            font-size: 0.9rem;
          }

          &.off {
            color: main-palette(500);
          }
          &.on {
            color: accent-palette(700) !important;
          }

          svg {
            display: none;
          }
        }

        .ant-table-column-sorter-up:before {
          content: '\eab9' !important;
          margin-bottom: $module-rem / 2;
        }

        .ant-table-column-sorter-down:before {
          content: '\eab2' !important;
        }
      }
    }
  }
  .ant-table-tbody {
    > tr {
      > td {
        background: $_bg;
      }
      &:hover:not(.ant-table-expanded-row) {
        > td {
          background-color: rgba(main-palette(500), 0.06);
        }
      }
    }
  }

&.ant-table-wrapper .ant-table-column-sorter{
  color: accent-contrast(500) !important;
}
  &.accent-header .ant-table .ant-table-thead {
    tr > th {
      background-color: accent-palette(500) !important;
      color: accent-contrast(500) !important;
    }
  }

  .ant-table-pagination.ant-pagination {
    float: left;
  }
}

.ant-table-filter-trigger{
color: accent-contrast(500) !important;
}