@import '../../../assets/sass/variables';

.text {
  display: flex;
  flex-direction: column;
}
.form-header {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  margin-bottom: 1rem;
}
.custome_width_table {
  width: 33% !important;
}

.form-input {
  width: 50%;
}
.min_custom_width {
  min-width: 5rem !important;
}

// upload image scss
.MultipleImgUpload {
  margin-top: 1rem;
  .fileInputButton {
    padding: 8px 16px;
    background-color: accent-palette(500);
    font-size: 1rem;
    font-weight: 700;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    transition: 0.3s;

    &:hover {
      background: accent-palette(300);
    }
  }

  .image-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 20px;

    .image-container {
      position: relative;
      width: 150px;
      height: auto;
      border: 1px solid #ccc;
      border-radius: 4px;
      overflow: hidden;

      .preview-image {
        width: 100%;
        height: auto;
        object-fit: cover;
      }

      .delete-button {
        position: absolute;
        top: 5px;
        right: 5px;
        background: none;
        border: none;
        cursor: pointer;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50%;
        background-color: rgba(255, 0, 0, 0.7);
        width: 24px;
        height: 24px;

        &:hover {
          background-color: rgba(255, 0, 0, 0.8);
        }
      }
    }
  }
}

.custom_labels {
  padding: 1rem o !important;
}

.inputNumber::-webkit-inner-spin-button,
.inputNumber::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.inputNumber {
  -moz-appearance: textfield; /* Firefox */
  border-radius: 6px !important;
  height: 34px !important;
}

.formula-row table tbody tr {
  background-color: #ffff5c;
}

.sectionStyle {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 8px;
  margin-top: 32px;
  position: relative;
}

.floatHeading {
  position: absolute;
  top: -15px;
  left: 13px;
  padding: 0 8px;
  font-size: 18px;
  font-weight: normal;
  background: white;
  color: darkgray;
}
